import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type CarouselStatsSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './CarouselStatsSliceTypes';
import { CarouselStats } from '@yoweb/ui/components/sections/CarouselStats';

type CarouselStatsSliceProps = {
  slice: CarouselStatsSliceResponse;
};

const CarouselStatsSlice = ({ slice }: CarouselStatsSliceProps) => {
  const carouselSlides = slice.items.map((item) => ({
    image: {
      url: item.itemImage.url ?? '',
      alt: item.itemImage.alt,
    },
    title: item.itemTitle,
    source: item.itemSource,
    sourceHref: item.itemSourceHref.url,
    imageOnLeft: item.imageOnLeft,
  }));

  const props = {
    carouselSlides,
  };

  return <CarouselStats {...props} />;
};

export default withPrismicGuardian(CarouselStatsSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
