import type { ImageField, RichTextField } from '@prismicio/types';
import type { Colors } from 'styled-components';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const ColoredCtaWithFigureSliceType = 'colored_cta_with_figure_slice';

// Required fields in slice
export const requiredPrimaryFields = [
  'label',
  'title',
  'text',
  'image',
  'backgroundColor',
] as const; // Array of all required primary fields.

type Primary = {
  label: string;
  title: string;
  text: RichTextField;
  image: ImageField;
  backgroundColor: keyof Colors;
};

export type ColoredCtaWithFigureSliceResponse = PrismicSlice<
  typeof ColoredCtaWithFigureSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>
>;
