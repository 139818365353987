import * as prismicHelpers from '@prismicio/helpers';

import {
  type ColoredCtaWithFormSliceResponse,
  requiredPrimaryFields,
} from './ColoredCtaWithFormSliceTypes';
import { CtaWithFormSection } from '@yoweb/ui/components/sections/CtaWithFormSection';
import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';

type ColoredCtaWithFormSliceProps = {
  slice: ColoredCtaWithFormSliceResponse;
  children?: ReactNode;
};

const ColoredCtaWithFormSlice = ({ slice, children, ...rest }: ColoredCtaWithFormSliceProps) => {
  const {
    ctaTitle,
    ctaDescription,
    formTitle,
    formDescription,
    backgroundColor,
    buttonLabel,
    atBottom,
  } = slice.primary;

  const props = {
    ctaTitle: prismicHelpers.asText(ctaTitle),
    ctaDescription: ctaDescription ? prismicHelpers.asText(ctaDescription) : undefined,
    formTitle: prismicHelpers.asText(formTitle),
    formDescription: formDescription ? prismicHelpers.asText(formDescription) : undefined,
    backgroundColor: backgroundColor ?? 'base000',
    buttonLabel: buttonLabel ? prismicHelpers.asText(buttonLabel) : undefined,
    atBottom,
  };

  return (
    <CtaWithFormSection {...props} {...rest}>
      {children}
    </CtaWithFormSection>
  );
};

export default withPrismicGuardian(ColoredCtaWithFormSlice, {
  primaryRequired: requiredPrimaryFields,
});
