import styled from 'styled-components';
import type { ReactNode } from 'react';

import { media } from '@yoweb/ui/styles/utils';
import PhotoCaption, { type PhotoCaptionProps } from './PhotoCaption';
import PhotoQuote, { type PhotoQuoteProps } from './PhotoQuote';
import { Box } from '@yoweb/ui/components/Box';
import { getSpace } from '@yoweb/ui/styles/utils/theme';

type PhotosGridProps = {
  children: ReactNode;
  caption?: PhotoCaptionProps;
  quote?: PhotoQuoteProps;
} & DataTestId;

const PhotoGrid = ({ children, quote, caption }: PhotosGridProps) => {
  if (quote && caption) {
    throw new Error('You can either have quote or caption, not both.');
  }

  const captionOrQuote = (
    <>
      {caption && <PhotoCaption {...caption} />}
      {quote && <PhotoQuote {...quote} />}
    </>
  );

  return (
    <>
      <Grid data-testid="photo-grid" isQuote={!!quote}>
        {children}
        <Box display={{ _: 'none', md: 'block' }}>{captionOrQuote}</Box>
      </Grid>
      <Box display={{ _: 'block', md: 'none' }}>{captionOrQuote}</Box>
    </>
  );
};

const imagesAndTextRatio = Number((392 / 184).toFixed(2));
const imagesAndQuoteRatio = Number((392 / 288).toFixed(2));

const Grid = styled.div<{ isQuote: boolean }>`
  align-items: 'baseline';
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${getSpace('normal2')};
  grid-template-columns: 1fr;
  justify-content: center;

  ${media.md`
    grid-template-columns: 1fr 1fr;
  `}

  ${({ isQuote }) => {
    const ratio = `${isQuote ? imagesAndQuoteRatio : imagesAndTextRatio}fr`;

    return media.lg`
      grid-template-columns: ${`${ratio} ${ratio} 1fr`};
      align-items: end;
    `;
  }}
`;

export default PhotoGrid;
