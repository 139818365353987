import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Image from 'next/legacy/image';

import { Container } from '@yoweb/ui/components/containers';
import { Center } from '@yoweb/ui/components/Grid/MarketingGrid';
import { Title } from '@yoweb/ui/components/typography';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import AnimateIn, { InView, DURATIONS } from '@yoweb/ui/components/AnimateIn';
import MarketingCarousel from '@yoweb/ui/components/marketing/Carousel/MarketingCarousel';
import { Box } from '@yoweb/ui/components/Box';
import { Grid } from '@yoweb/ui/components/Grid';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { useMobileOnly, useTabletOnly } from '@yoweb/ui/components/Responsive';

type CarouselStatsProps = {
  carouselSlides: {
    image: {
      url: string;
      alt: string | null | undefined;
    };
    title: string;
    source: string;
    sourceHref: string;
    imageOnLeft: boolean;
  }[];
};

export const CarouselStats = ({ carouselSlides }: CarouselStatsProps) => {
  const isMobile = useMobileOnly();
  const isTablet = useTabletOnly();
  const [imageHeight, setImageHeight] = useState(200);

  useEffect(() => {
    if (isMobile) {
      setImageHeight(200);
    } else if (isTablet) {
      setImageHeight(560);
    } else {
      setImageHeight(720);
    }
  }, [isMobile, isTablet]);

  return (
    <Container withGrid>
      <Center>
        <Box mt={{ _: 'normal3', lg: 'medium2' }}>
          <InView>
            {(isInView) => (
              <AnimateIn
                isVisible={isInView}
                config={{
                  opacity: { animate: true, duration: DURATIONS.d67 },
                  y: { animate: true, offset: 30, duration: DURATIONS.d33 },
                }}
              >
                <MarketingCarousel
                  data-testid="marketing-carousel"
                  slideSource={carouselSlides}
                  slides={carouselSlides.map(({ image, title, imageOnLeft }, index) => (
                    <StatsGrid key={index} right>
                      <StatsImage imageOnLeft={imageOnLeft}>
                        <Image
                          alt={image.alt ?? undefined}
                          height={imageHeight}
                          objectFit="contain"
                          src={image.url}
                          width={isMobile ? 327 : 600}
                        />
                      </StatsImage>
                      <StatsText imageOnLeft={imageOnLeft}>
                        <Title size={{ _: 'sm', md: 'xs', lg: 'sm' }}>{title}</Title>
                      </StatsText>
                    </StatsGrid>
                  ))}
                />
              </AnimateIn>
            )}
          </InView>
        </Box>
      </Center>
    </Container>
  );
};

const StatsText = styled.div<{ imageOnLeft?: boolean }>`
  grid-row: 2;

  ${media.md`
    grid-row: 1;
    grid-column: 1 / 7;
  `}
  ${({ imageOnLeft }) =>
    imageOnLeft &&
    css`
      ${media.md`
          grid-column: 8 / 13;
        `}
    `}
`;

const StatsImage = styled.div<{ imageOnLeft?: boolean }>`
  grid-row: 1;
  justify-self: center;
  max-width: 300px;
  width: 100%;

  ${media.md`
    grid-column: 7 / 13;
  `}

  ${({ imageOnLeft }) =>
    imageOnLeft &&
    css`
      ${media.md`
        grid-column: 1 / 8;
      `}
    `}
`;

const StatsGrid = styled(Grid)<{ left?: boolean; right?: boolean }>`
  && {
    grid-gap: ${getSpace('medium1')};
    padding: ${getSpace('medium2')} ${getSpace('medium1')};
    text-align: center;

    ${media.md<{ left?: boolean; right?: boolean }>`
      align-items: center;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${getSpace('normal3')};
      min-height: ${pxToRem(300)};
      padding: 0 ${getSpace('large1')};
      text-align: left;
    `}

    ${media.lg<{ left?: boolean; right?: boolean }>`
      min-height: ${pxToRem(360)};
    `}
  }
`;
