import type { RichTextField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';
import type { BoxProps } from '@yoweb/ui/components/Box';

export const ColoredCtaWithFormSliceType = 'colored_cta_with_form_slice';

// Required fields in slice
export const requiredPrimaryFields = ['ctaTitle', 'formTitle', 'atBottom'] as const; // Array of all required primary fields.
export const requiredItemsFields = [] as const; // Array of all required items fields.

type Primary = {
  ctaTitle: RichTextField;
  ctaDescription?: RichTextField;
  formTitle: RichTextField;
  formDescription?: RichTextField;
  backgroundColor?: BoxProps['backgroundColor'];
  buttonLabel?: RichTextField;
  atBottom: boolean;
};

export type ColoredCtaWithFormSliceResponse = PrismicSlice<
  typeof ColoredCtaWithFormSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>
>;
