import type { ImageField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const LargeMediaSectionSliceType = 'large_media_section_slice';

// Required fields in slice
export const requiredPrimaryFields = ['title', 'image', 'mobileImage'] as const; // Array of all required primary fields.
export const requiredItemsFields = [] as const; // Array of all required items fields.

type Primary = {
  title: string;
  subtitle?: string;
  image: ImageField;
  mobileImage: ImageField;
};

export type LargeMediaSectionSliceResponse = PrismicSlice<
  typeof LargeMediaSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>
>;
