import styled from 'styled-components';

import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { Text } from '@yoweb/ui/components/typography';
import { Box } from '@yoweb/ui/components/Box';

export type PhotoCaptionProps = {
  title: string;
  description: string;
};

const PhotoCaption = ({ title, description }: PhotoCaptionProps) => (
  <TextWrapper>
    <figcaption>
      <Box pb="small3" maxWidth={396}>
        <Text as="cite" size="sm" weight="bold">
          {title}
        </Text>
      </Box>
      <Box maxWidth={396}>
        <Text size="sm">{description}</Text>
      </Box>
    </figcaption>
  </TextWrapper>
);

const TextWrapper = styled.figure`
  margin-top: ${getSpace('normal2')};

  ${media.lg`
    align-self: end;
    max-width: ${pxToRem(184)};
  `}
`;

export default PhotoCaption;
