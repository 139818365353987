import { PrismicRichText } from '@prismicio/react';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import { CardGrid } from '@yoweb/ui/components/marketing/CardGrid';
import { ProjectCard } from '@yoweb/ui/components/marketing/ProjectCard';
import {
  type ProjectCardSliceResponse,
  requiredPrimaryFieldsWithGrid,
  requiredItemsFields,
} from './ProjectCardSliceTypes';
import { largeTextSerializer } from '@yoweb/prismic-slicemachine/utils/serializers';

type ProjectCardSliceProps = {
  slice: ProjectCardSliceResponse;
};

const ProjectCardSlice = ({ slice }: ProjectCardSliceProps) => {
  const cards = slice.items.map(({ author, title, text, authorTitle, image }) => (
    <ProjectCard
      key={title}
      title={title}
      quote={{
        text,
        author,
        authorTitle,
      }}
      image={image.url ?? ''}
      imageAltText={image?.alt ?? ''}
    />
  ));

  if (slice.variation === 'projectCardSliceWithGrid') {
    const { title, subtitle } = slice.primary;

    const props = {
      title,
      subtitle: subtitle ? (
        <PrismicRichText field={subtitle} components={largeTextSerializer} />
      ) : undefined,
    };

    return <CardGrid {...props}>{cards}</CardGrid>;
  }

  return <>{cards}</>;
};

export default withPrismicGuardian(ProjectCardSlice, {
  type: 'variations',
  variations: {
    'default-slice': {
      itemsRequired: requiredItemsFields,
    },
    projectCardSliceWithGrid: {
      primaryRequired: requiredPrimaryFieldsWithGrid,
      itemsRequired: requiredItemsFields,
    },
  },
});
