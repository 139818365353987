import { useState } from 'react';
import styled from 'styled-components';
import Image, { type ImageProps } from 'next/legacy/image';

import { useTranslation } from '@yoweb/next-i18next';
import { Box } from '@yoweb/ui/components/Box';
import { th } from '@yoweb/ui/styles/utils/theme';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { ScaleIn } from '@yoweb/ui/components/AnimateIn';
import { isString } from '@yoweb/utils/isString';
import { Title, Text } from '@yoweb/ui/components/typography';
import { Button } from '@yoweb/ui/components/buttons';
import { Modal } from '@yoweb/ui/components/Modal';

type HeroWithFormInModalProps = {
  children: React.ReactNode;
  desktopImage: string;
  mobileImage: string;
  imageAlt: string;
  title: string;
  description: string;
  buttonLabel: string;
};

const HeroWithFormInModal = ({
  desktopImage,
  mobileImage,
  imageAlt,
  title,
  description,
  buttonLabel,
  children,
}: HeroWithFormInModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation(['waitlist']);

  const modalActions = {
    open: () => setIsModalOpen(true),
    close: () => setIsModalOpen(false),
  };

  const commonImageProps: Partial<ImageProps> = {
    priority: true,
    layout: 'fill',
    alt: imageAlt,
  };

  const desktopImageProps: Partial<ImageProps> = {
    objectFit: 'cover',
    ...commonImageProps,
    ...(isString(desktopImage)
      ? {
          placeholder: 'blur',
          blurDataURL: `${desktopImage}&blur=2000`,
        }
      : { placeholder: 'blur' }),
  };
  const mobileImageProps: Partial<ImageProps> = {
    objectFit: 'cover',
    ...commonImageProps,
    ...(isString(mobileImage)
      ? {
          placeholder: 'blur',
          blurDataURL: `${mobileImage}&blur=2000`,
        }
      : { placeholder: 'blur' }),
  };

  return (
    <>
      <ScaleIn isVisible>
        {/* Desktop layout */}
        <Box
          display={{ _: 'none', lg: 'block' }}
          backgroundColor="base100"
          height={800}
          maxHeight={'100vh'}
          position="relative"
        >
          <HeroCover>
            <DesktopImage>
              <Image
                src={desktopImage}
                objectPosition="center"
                {...(!isString(desktopImage) ? { placeholder: 'blur' } : {})}
                {...desktopImageProps}
              />
            </DesktopImage>
          </HeroCover>
          {/* Hero card container */}
          <BoxContainer width="100%" height="100%" position="relative" mx="auto">
            <Box
              position="absolute"
              bottom={'large2'}
              left="normal1"
              backgroundColor="base000"
              radii="large"
              width={492}
              p={'medium1'}
            >
              <Title>{title}</Title>
              <Box mt={20}>
                <Title size="xs" variant="muted" weight="regular">
                  {description}
                </Title>
              </Box>
              <Box mt="normal2">
                <Button onClick={modalActions.open} weight="bold">
                  {buttonLabel}
                </Button>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
        {/* Mobile layout */}
        <Box display={{ _: 'block', lg: 'none' }} mt="xl3">
          <Box mx="normal2" display="grid" justifyItems="center">
            <Title size="md" textAlign={'center'}>
              {title}
            </Title>
            <Box mt="small3">
              <Text size="lg" textAlign="center">
                {description}
              </Text>
            </Box>
            <Box mt="normal3">
              <Button onClick={modalActions.open}>{buttonLabel}</Button>
            </Box>
          </Box>
          <MobileImage>
            <Image
              src={mobileImage}
              objectPosition="center"
              {...(!isString(mobileImage) ? { placeholder: 'blur' } : {})}
              {...mobileImageProps}
            />
          </MobileImage>
        </Box>
      </ScaleIn>

      {/* Modal Form  */}
      <Modal
        testId="hero-modal-waitlist"
        hasCloseButton
        onClose={modalActions.close}
        isOpen={isModalOpen}
        noPaddingBottom
      >
        <Box pb="normal2">
          <Title size="xs">{t('waitlist:personal.waitlist-form-title')}</Title>
          {children}
        </Box>
      </Modal>
    </>
  );
};

const BoxContainer = styled(Box)`
  && {
    max-width: ${th.getSize('containerWidth')};
  }
`;

const HeroCover = styled.div`
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

const DesktopImage = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

const MobileImage = styled.div`
  position: relative;
  width: 100%;
  margin-top: ${th.getSpace('medium2')};
  height: ${pxToRem(208)};

  ${media.md`
    height: ${pxToRem(350)};
  `}
`;

export { HeroWithFormInModal };
