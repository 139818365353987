import styled from 'styled-components';
import Image, { type ImageProps } from 'next/legacy/image';
import React from 'react';

import { Title } from '@yoweb/ui/components/typography';
import { Container } from '@yoweb/ui/components/containers';
import { pxToRem, media, breakpoints } from '@yoweb/ui/styles/utils';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { useViewport } from '@yoweb/ui/hooks/useViewport';
import { Box } from '@yoweb/ui/components/Box';

const HEADER_HEIGHT_DESKTOP = 130;

export type HeroWithFormProps = {
  title: string;
  description: string;
  buttonLabel?: string;
  desktopImage: string;
  mobileImage: string;
  children?: ReactNode;
} & DataTestId;

export const IDS = {
  container: 'hero-with-form-container',
  form: 'form-wrapper',
  image: 'image-wrapper',
};

type HeroWithFormChildProps = {
  title: string;
  description: string;
  buttonLabel: string;
  large: true;
};

export const HeroWithForm = ({
  title,
  description,
  desktopImage,
  mobileImage,
  buttonLabel,
  children,
}: HeroWithFormProps) => {
  const { viewportWidth } = useViewport();

  const desktopImageProps: Partial<ImageProps> = {
    priority: true,
    layout: 'fixed',
    width: 704,
    height: 664,
    objectFit: 'cover',
  };

  const mobileImageProps: Partial<ImageProps> = {
    priority: true,
    layout: 'fill',
    objectFit: 'cover',
    objectPosition: 'top center',
  };

  /** Inject the child component sent from the parent with all the necessary props that the parent did not have access to at the time of sending */
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<HeroWithFormChildProps>, {
        title,
        description,
        buttonLabel,
        large: true,
      });
    }
    return child;
  });

  return (
    <HeroContainer withGrid fillTabletWidth data-testid={IDS.container}>
      <FormWrapper data-testid={IDS.form}>
        <Box mt={{ _: 'normal3', lg: 'none' }}>
          <Title size={{ _: 'md', lg: 'lg' }}>{title}</Title>
        </Box>
        {description && (
          <Box mt="normal1">
            <Title as="p" size="xs" variant="muted" weight="regular">
              {description}
            </Title>
          </Box>
        )}
        {childrenWithProps}
      </FormWrapper>
      <ImageWrapper data-testid={IDS.image}>
        {viewportWidth >= breakpoints.lg ? (
          <Image src={desktopImage} {...desktopImageProps} />
        ) : (
          <Image src={mobileImage} {...mobileImageProps} />
        )}
      </ImageWrapper>
    </HeroContainer>
  );
};

const HeroContainer = styled(Container)`
  && {
    padding-top: 0;
    align-items: start;
    grid-template-rows: 3;
    margin: 0;
    ${media.lg`
      padding-top: ${pxToRem(HEADER_HEIGHT_DESKTOP + 40)};
      grid-template-rows: 1;
      margin: auto;
    `}
  }
`;

const ImageWrapper = styled.div`
  grid-column: 1/5;
  grid-row: 1/3;
  position: relative;
  overflow: hidden;
  max-height: 75vh;
  height: ${pxToRem(264)};

  ${media.md`
    max-height: none;
    grid-column: 1/9;
    height: ${pxToRem(264 * 1.5)};
  `}

  ${media.lg`
    grid-column: 6/13;
    height: auto;
  `}
`;

const FormWrapper = styled.div`
  grid-column: 1/5;
  grid-row: 3/4;
  position: relative;
  padding-left: ${getSpace('normal2')};
  padding-right: ${getSpace('normal2')};

  ${media.md`
    grid-column: 1/9;
    max-width: ${pxToRem(648)};
    margin: auto;
    
  `}

  ${media.lg`
    grid-column: 1/6;
    grid-row: 1/2;
    margin-top: ${getSpace('large3')};
    padding-right: 0;
  `}
`;
