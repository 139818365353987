import type { Colors } from 'styled-components';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const AnimatedColoredSectionSliceType = 'animated_colored_section_slice';

// Required fields in slice
export const requiredPrimaryFields = ['title', 'ctaHref', 'ctaLabel'] as const; // Array of all required primary fields.
export const requiredItemsFields = ['itemText'] as const; // Array of all required items fields.

type Primary = {
  title: string;
  ctaHref: string;
  ctaLabel: string;
  bgColor?: keyof Colors;
  titleMaxWidth?: number;
  animation: 'juggling-a-lot' | 'wellness-pros-animation-data';
  navId?: string;
};

type Items = {
  itemText: string;
};

export type AnimatedColoredSectionSliceResponse = PrismicSlice<
  typeof AnimatedColoredSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Items>
>;
