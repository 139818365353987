import type { FilledLinkToWebField, ImageField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const CarouselStatsSliceType = 'carousel_stats_slice';

// Required fields in slice
export const requiredPrimaryFields = [] as const; // Array of all required primary fields.
export const requiredItemsFields = [
  'itemImage',
  'itemTitle',
  'itemSource',
  'itemSourceHref',
  'imageOnLeft',
] as const; // Array of all required items fields.

type Primary = Record<string, unknown>;

type Item = {
  itemImage: ImageField;
  itemTitle: string;
  itemSource: string;
  itemSourceHref: FilledLinkToWebField;
  imageOnLeft: boolean;
};

export type CarouselStatsSliceResponse = PrismicSlice<
  typeof CarouselStatsSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>
>;
