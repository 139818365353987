import { useEffect, useState } from 'react';
import styled, { type Colors } from 'styled-components';
import Link from 'next/link';

import { Container } from '@yoweb/ui/components/containers/Container';
import { Lottie } from '@yoweb/ui/components/Lottie';
import { InView, StaggerElements } from '@yoweb/ui/components/AnimateIn';
import { Button } from '@yoweb/ui/components/buttons';
import { ContainerWideColor } from '@yoweb/ui/components/marketing/ContainerWideColor';
import { Title, Text } from '@yoweb/ui/components/typography';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { Flex } from '@yoweb/ui/components/Flex';
import { CircleCheckIcon } from '@yoweb/ui/components/Icon';
import { Box } from '@yoweb/ui/components/Box';

export type AnimatedColoredSectionProps = {
  bgColor?: keyof Colors;
  title: string;
  titleMaxWidth?: number;
  ctaHref?: string;
  ctaLabel?: string;
  animationData: object;
  bulletPoints?: string[];
  animation?: 'juggling-a-lot' | 'wellness-pros-animation-data';
};

const animationMap = {
  'juggling-a-lot': () => import('@yoweb/ui/lottieAnimations/juggling-a-lot.json'),
  'wellness-pros-animation-data': () =>
    import('@yoweb/ui/lottieAnimations/wellness-pros-animation-data.json'),
};

export const AnimatedColoredSection = ({
  bgColor = 'morning',
  title,
  titleMaxWidth,
  ctaHref,
  ctaLabel,
  bulletPoints,
  animation = 'juggling-a-lot',
}: AnimatedColoredSectionProps) => {
  const [lottieState, setLottieState] = useState<unknown>(undefined);

  useEffect(() => {
    const setupLottie = async () => {
      const lottieData = await animationMap[animation]();
      setLottieState(lottieData as unknown);
    };

    void setupLottie();
  }, [animation, setLottieState]);
  return (
    <InView>
      {(isInView) => (
        <ColoredSection bgColor={bgColor}>
          <ColoredContainer withGrid>
            <ColoredSectionContent data-testid="animated-colored-section">
              <StaggerElements isInView={isInView}>
                <TextContent>
                  <Box mb="normal1">
                    <Title
                      size={{ _: 'md', lg: 'xl' }}
                      maxWidth={titleMaxWidth}
                      whiteSpace="pre-wrap"
                    >
                      {title}
                    </Title>
                  </Box>
                  {bulletPoints &&
                    bulletPoints.length > 0 &&
                    bulletPoints.map((item, i) => (
                      <div key={`${item}-${i}`}>
                        <Flex display="inline-flex" alignItems={'flex-start'} gap="small3">
                          <CircleCheckIcon
                            style={{ alignSelf: 'flex-start' }}
                            width={32}
                            height={32}
                          />
                          <CheckmarkText>{item}</CheckmarkText>
                        </Flex>
                      </div>
                    ))}
                  <Box mt="normal1">
                    {ctaHref && (
                      <Link href={ctaHref} passHref legacyBehavior>
                        <Button as="a" size={{ _: 'md', lg: 'lg' }}>
                          {ctaLabel}
                        </Button>
                      </Link>
                    )}
                  </Box>
                </TextContent>
              </StaggerElements>
            </ColoredSectionContent>
            <ColoredSectionImage>
              <Lottie animationData={lottieState} />
            </ColoredSectionImage>
          </ColoredContainer>
        </ColoredSection>
      )}
    </InView>
  );
};

const ColoredSection = styled(ContainerWideColor)`
  && {
    margin-bottom: ${pxToRem(-20)};
    ${media.md`
      margin-bottom: ${getSpace('normal3')};
    `}
  }
`;

const ColoredContainer = styled(Container)`
  && {
    row-gap: ${getSpace('normal3')};
    ${media.xs`
      padding: ${pxToRem(44)} 0 ${pxToRem(70)};
    `}
    ${media.md`
      padding: ${pxToRem(125)} 0 ${pxToRem(156)};
    `}
  }
`;

const ColoredSectionContent = styled.div`
  align-content: center;
  display: grid;
  grid-column: 1 / span 12;
  text-align: center;
  ${media.md`
    grid-column: 1 / span 5;
    text-align: left;
  `}
  ${media.lg`
    grid-column: 2 / span 5;
  `}
`;

const TextContent = styled.div`
  display: grid;
  justify-items: center;
  row-gap: ${getSpace('small3')};
  ${media.md`
    row-gap: ${getSpace('normal1')};
    justify-items: flex-start;
  `}
`;

const ColoredSectionImage = styled.div`
  grid-column: 1 / span 12;
  grid-row: 1;
  mix-blend-mode: darken;
  position: relative;
  overflow: hidden;
  height: ${pxToRem(263)};

  svg {
    position: absolute;
    transform: translate3d(0, 0, 0) scale(1.5) !important;
  }
  ${media.md`
    grid-column: 6 / span 6;
  `}
  ${media.lg`
    height: 100vh;
    max-height: ${pxToRem(422)};
    grid-column: 7 / span 6;
    svg {
      position: absolute;
      transform: translate3d(0, 0, 0) scale(1.8) !important;
    }
  `}
`;

const CheckmarkText = styled(Text)`
  font-size: 24px;
  max-width: ${pxToRem(300)};
  text-align: left;
`;
