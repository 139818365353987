import styled from 'styled-components';

import { PhotoGrid, Photo } from '@yoweb/ui/components/marketing/Photo';
import AnimateIn, { InView, STAGGER_DELAY } from '@yoweb/ui/components/AnimateIn';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { BodyHeading, BodyHeader } from '@yoweb/ui/components/marketing/MarketingTexts';
import type { PhotoCaptionProps } from '@yoweb/ui/components/marketing/Photo/PhotoCaption';
import type { PhotoQuoteProps } from '@yoweb/ui/components/marketing/Photo/PhotoQuote';
import { GridItem, Center } from '@yoweb/ui/components/Grid/MarketingGrid';
import { Container } from '@yoweb/ui/components/containers';

type PhotosWithQuoteType = {
  label?: string | null;
  title?: string | ReactNode | null;
  image1: string;
  alt1?: string | null;
  image2: string;
  alt2?: string | null;
  quote?: PhotoQuoteProps;
  caption?: PhotoCaptionProps;
};

export const PhotosWithQuote = ({
  label,
  title,
  image1,
  alt1,
  image2,
  alt2,
  quote,
  caption,
}: PhotosWithQuoteType) => (
  <InView>
    {(isInView) => (
      <Container withGrid>
        <Center>
          <AnimateIn isVisible={isInView}>
            {label && title && <BodyHeader title={title} label={label} />}
            {!label && title && <StyledTitle>{title}</StyledTitle>}
          </AnimateIn>
        </Center>
        <GridItem
          gridColumn={{
            _: '1 / span 4',
            md: '1 / span 8',
            lg: `${quote ? '2 / span 11' : '2 / span 10'}`,
          }}
          pb={{ _: 'small3', lg: 'normal3' }}
          pt={{ _: 'normal3', lg: 'medium2' }}
        >
          <AnimateIn isVisible={isInView} delay={STAGGER_DELAY}>
            <PhotoGrid quote={quote} caption={caption}>
              <Photo width={392} height={432} src={image1} alt={alt1 ?? ''} />
              <Photo width={392} height={432} src={image2} alt={alt2 ?? ''} />
            </PhotoGrid>
          </AnimateIn>
        </GridItem>
      </Container>
    )}
  </InView>
);

const StyledTitle = styled(BodyHeading)`
  && {
    margin-bottom: ${getSpace('normal2')};
  }
`;
