import styled from 'styled-components';

import { Container } from '@yoweb/ui/components/containers/Container';
import { Box } from '@yoweb/ui/components/Box';
import { InView, StaggerElements } from '@yoweb/ui/components/AnimateIn';
import { Title } from '@yoweb/ui/components/typography';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { getSpace } from '@yoweb/ui/styles/utils/theme';

type VideoColoredSectionProps = {
  title: string | ReactNode;
  subtitle: string | ReactNode;
  video: string;
};

export const VideoColoredSection = ({ title, subtitle, video }: VideoColoredSectionProps) => (
  <InView>
    {(isInView) => (
      <Box pt={{ _: 'large3', md: 'xl2' }} pb={{ _: 'large2', md: 'xl2' }}>
        <YohanaGrid withGrid>
          <YohanaSectionTextContent>
            <StaggerElements isInView={isInView}>
              <Title size={{ _: 'lg', md: 'xl' }} maxWidth={400}>
                {title}
              </Title>
              <Title weight="regular" size={{ _: 'xs', md: 'sm' }} variant="muted">
                {subtitle}
              </Title>
            </StaggerElements>
          </YohanaSectionTextContent>
          <YohanaSectionVideo>
            <VideoLoop autoPlay muted playsInline loop id="pro-video">
              <source src={video} type="video/mp4" />
            </VideoLoop>
          </YohanaSectionVideo>
        </YohanaGrid>
      </Box>
    )}
  </InView>
);

const YohanaGrid = styled(Container)`
  align-items: center;
  row-gap: ${getSpace('medium2')};
`;

const YohanaSectionTextContent = styled.div`
  grid-column: 1 / span 4;
  display: grid;
  align-items: center;
  row-gap: ${getSpace('normal2')};

  ${media.md`
    grid-column: 1 / span 8;
  `}

  ${media.lg`
    grid-column: 1 / span 5;
  `}
`;

const YohanaSectionVideo = styled.div`
  grid-column: 1 / span 4;
  position: relative;
  height: ${pxToRem(355)};

  ${media.md`
    grid-column: 1 / span 8;
  `}

  ${media.lg`
    grid-column: 7 / span 6;
    height: auto;
    min-height: ${pxToRem(593)};
  `}
`;

const VideoLoop = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
