import * as prismicHelpers from '@prismicio/helpers';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type VideoColoredSectionSliceResponse,
  requiredPrimaryFields,
} from './VideoColoredSectionSliceTypes';
import { VideoColoredSection } from '@yoweb/ui/components/sections/VideoColoredSection';

type VideoColoredSectionSliceProps = {
  slice: VideoColoredSectionSliceResponse;
};

const VideoColoredSectionSlice = ({ slice, ...rest }: VideoColoredSectionSliceProps) => {
  const { title, subtitle, video } = slice.primary;

  const props = {
    title: prismicHelpers.asText(title),
    subtitle: prismicHelpers.asText(subtitle),
    video,
  };

  return <VideoColoredSection {...props} {...rest} />;
};

export default withPrismicGuardian(VideoColoredSectionSlice, {
  primaryRequired: requiredPrimaryFields,
});
