import styled from 'styled-components';
import type { ReactNode } from 'react';

import { Container } from '@yoweb/ui/components/containers';
import { Title, Text } from '@yoweb/ui/components/typography';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { getSpace } from '@yoweb/ui/styles/utils/theme';

export type CardGridProps = {
  title?: string;
  subtitle?: string | ReactNode;
  children: ReactNode[];
} & DataTestId;

export const CardGrid = ({ title, subtitle, children }: CardGridProps) => {
  // Split cards so the second column contains more elements if the child count is not even
  const splitIndex = Math.floor(children.length / 2);
  const column1 = children.slice(0, splitIndex);
  const column2 = children.slice(splitIndex, children.length);

  return (
    <Grid withGrid data-testid="card-grid-wrapper">
      <Column>
        {(title || subtitle) && (
          <Header>
            {title && (
              <Title size={{ _: 'lg', lg: 'xxl' }} whiteSpace="pre-wrap">
                {title}
              </Title>
            )}
            {subtitle && (
              <Text size="xl" variant="muted">
                {subtitle}
              </Text>
            )}
          </Header>
        )}
        {column1?.map((card, index) => <CardWrap key={`column1-${index}`}>{card}</CardWrap>)}
      </Column>
      <Column>
        {column2?.map((card, index) => <CardWrap key={`column2-${index}`}>{card}</CardWrap>)}
      </Column>
    </Grid>
  );
};

const Grid = styled(Container)`
  && {
    padding: ${pxToRem(80)} 0 ${getSpace('normal4')};

    ${media.md`
    padding: ${pxToRem(144)} 0 ${getSpace('medium1')};
  `}
  }
`;

const Column = styled.div`
  grid-column: 1 / span 4;

  ${media.md`
    &:nth-child(1) {
      grid-column: 1 / span 4;
    }
    &:nth-child(2) {
      grid-column: 5 / span 4;
    }
  `}
  ${media.lg`
    &:nth-child(1) {
      grid-column: 1 / span 5;
    }
    &:nth-child(2) {
      grid-column: 7 / span 5;
    }
  `}
`;

const Header = styled.div`
  display: grid;
  grid-gap: ${getSpace('normal2')};
  margin-bottom: ${pxToRem(88)};

  ${media.md`
    grid-gap: ${getSpace('normal3')};
    margin-bottom: ${pxToRem(180)}
  `}

  ${media.lg`
    margin-bottom: ${pxToRem(216)};
  `}
`;

const CardWrap = styled.div`
  margin-bottom: ${getSpace('large3')};
`;
