import styled from 'styled-components';
import Image, { type ImageProps } from 'next/legacy/image';

import { StaggerElements, InView } from '@yoweb/ui/components/AnimateIn';
import { pxToRem } from '@yoweb/ui/styles/utils';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { Title } from '@yoweb/ui/components/typography';
import { Box } from '@yoweb/ui/components/Box';
import { RenderOn } from '@yoweb/ui/components/RenderOn/RenderOn';

export type LargeMediaSectionProps = {
  title: string;
  subtitle?: string;
  image: ImageProps;
  mobileImage: ImageProps;
} & DataTestId;

const imageDefaults: Partial<ImageProps> = {
  src: '',
  alt: '',
  layout: 'fill',
  objectFit: 'cover',
  objectPosition: 'center center',
  placeholder: 'blur',
};

export const LargeMediaSection = ({
  title,
  subtitle,
  image,
  mobileImage,
}: LargeMediaSectionProps) => {
  const defaultImageOpts: ImageProps = { ...imageDefaults, ...image } as ImageProps;
  const mobileImageOpts: ImageProps = { ...imageDefaults, ...mobileImage } as ImageProps;

  return (
    <Box
      display="flex"
      overflow="hidden"
      position="relative"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      backgroundColor="base200"
      minHeight={{ _: 692, md: 625 }}
      m={{ _: 'none', md: 'normal1' }}
      radii={{ _: 'none', md: 'medium' }}
    >
      <ImageWrap>
        <RenderOn.TabletAndDesktop>
          <Image {...defaultImageOpts} />
        </RenderOn.TabletAndDesktop>
        <RenderOn.MobileOnly>
          <Image {...mobileImageOpts} />
        </RenderOn.MobileOnly>
      </ImageWrap>

      <Box
        position="absolute"
        fontColor="base000"
        bottom={{ _: 'normal3', md: 'medium1' }}
        left={{ _: 'normal3', md: 'medium1' }}
        mr="normal4"
        maxWidth={!subtitle ? 609 : 500}
      >
        <InView>
          {(isInView) => (
            <StaggerElements isInView={isInView}>
              <Title size={subtitle ? { _: 'lg', md: 'xl' } : { _: 'md', md: 'xl' }}>{title}</Title>
              {subtitle && (
                <SubtitleWrapper>
                  <p>{subtitle}</p>
                </SubtitleWrapper>
              )}
            </StaggerElements>
          )}
        </InView>
      </Box>
    </Box>
  );
};

const ImageWrap = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  border-radius: ${getSpace('normal1')};
`;

const SubtitleWrapper = styled.div`
  max-width: ${pxToRem(435)};
  margin-top: ${pxToRem(20)};

  p {
    font-size: ${pxToRem(24)};
    font-weight: 400;
    line-height: 32px;
  }
`;
