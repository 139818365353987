import { PrismicRichText } from '@prismicio/react';

import {
  type ColoredCtaWithFigureSliceResponse,
  requiredPrimaryFields,
} from './ColoredCtaWithFigureSliceTypes';
import { ColoredCtaWithFigure } from '@yoweb/ui/components/sections/ColoredCtaWithFigure';
import { waitlistSerializer } from '@yoweb/prismic-slicemachine/utils/serializers';
import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';

type ColoredCtaWithFigureSliceProps = {
  slice: ColoredCtaWithFigureSliceResponse;
};

const ColoredCtaWithFigureSlice = ({ slice, ...rest }: ColoredCtaWithFigureSliceProps) => {
  const { label, title, text, image, backgroundColor } = slice.primary;

  const props = {
    label,
    title,
    text: <PrismicRichText field={text} components={waitlistSerializer} />,
    imageSrc: image.url ?? '',
    imageAlt: image.alt,
    backgroundColor,
  };

  return <ColoredCtaWithFigure {...props} {...rest} />;
};

export default withPrismicGuardian(ColoredCtaWithFigureSlice, {
  primaryRequired: requiredPrimaryFields,
});
