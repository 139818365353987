import * as prismicHelpers from '@prismicio/helpers';
import { PrismicRichText } from '@prismicio/react';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type ImagesWithQuoteSliceResponse,
  requiredPrimaryFields,
  requiredPrimaryFieldsWithCaption,
} from './ImagesWithQuoteSliceTypes';
import { PhotosWithQuote } from '@yoweb/ui/components/sections/PhotosWithQuote';
import { paragraphBodyLinkSerializer } from '@yoweb/prismic-slicemachine/utils/serializers';

type ImagesWithQuoteSliceProps = {
  slice: ImagesWithQuoteSliceResponse;
};

const ImagesWithQuoteSlice = ({ slice, ...rest }: ImagesWithQuoteSliceProps) => {
  const { label, title, image1, image2 } = slice.primary;

  const commonProps = {
    label,
    title: <PrismicRichText field={title} components={paragraphBodyLinkSerializer} />,
    image1: image1.url ? image1.url : '',
    alt1: image1?.alt,
    image2: image2.url ? image2.url : '',
    alt2: image2?.alt,
  };

  if (slice.variation === 'withCaption') {
    const withCaptionProps = {
      ...commonProps,
      caption: {
        title: slice.primary.captionTitle,
        description: slice.primary.captionDescription,
      },
    };

    return <PhotosWithQuote {...withCaptionProps} {...rest} />;
  }

  const withQuoteProps = {
    ...commonProps,
    quote: {
      quote: prismicHelpers.asText(slice.primary.quote),
      source: prismicHelpers.asText(slice.primary.source),
      sourceHref: slice.primary.sourceHref.url,
    },
  };

  return <PhotosWithQuote {...withQuoteProps} {...rest} />;
};

export default withPrismicGuardian(ImagesWithQuoteSlice, {
  type: 'variations',
  variations: {
    default: {
      primaryRequired: requiredPrimaryFields,
    },
    withCaption: {
      primaryRequired: requiredPrimaryFieldsWithCaption,
    },
  },
});
