import styled from 'styled-components';

import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { Title } from '@yoweb/ui/components/typography';
import SourceLink, { type SourceLinkProps } from '@yoweb/ui/components/marketing/SourceLink';

export type PhotoQuoteProps = SourceLinkProps & {
  quote: string;
};

const PhotoQuote = ({ quote, ...source }: PhotoQuoteProps) => (
  <QuoteWrapper>
    <Title as="blockquote" size={{ _: 'xs', md: 'sm' }} weight="regular">
      {quote}
    </Title>
    <QuoteSource>
      <SourceLink {...source} />
    </QuoteSource>
  </QuoteWrapper>
);

const QuoteWrapper = styled.figure`
  margin-top: ${getSpace('normal1')};

  ${media.md`
    margin-top: ${getSpace('normal2')};
  `}

  ${media.lg`
    align-self: center;
    max-width: ${pxToRem(288)};
  `}
`;

const QuoteSource = styled.div`
  margin-top: ${getSpace('normal1')};

  ${media.md`
    margin-top: ${getSpace('normal2')};
  `}
`;

export default PhotoQuote;
