import styled, { type Colors } from 'styled-components';
import Image from 'next/legacy/image';

import { ContainerWideColor as ColoredCardSection } from '@yoweb/ui/components/marketing/ContainerWideColor';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { Container } from '@yoweb/ui/components/containers';
import { StaggerElements, InView } from '@yoweb/ui/components/AnimateIn';
import { Box } from '@yoweb/ui/components/Box';
import { Title, Label } from '@yoweb/ui/components/typography';

type ColoredCtaWithFigureProps = {
  backgroundColor: keyof Colors;
  label?: string | null;
  title: string;
  text: string | ReactNode;
  imageSrc: string;
  imageAlt?: string | null;
};

export const ColoredCtaWithFigure = ({
  backgroundColor,
  label,
  title,
  text,
  imageSrc,
  imageAlt,
}: ColoredCtaWithFigureProps) => (
  <ColoredCardSection bgColor={backgroundColor}>
    <InteriorGrid withGrid>
      <TextContainer>
        <InView>
          {(isInView) => (
            <div>
              <StaggerElements isInView={isInView}>
                <div>
                  {label && (
                    <Box pb={{ _: 18, md: 22 }}>
                      <Label size={{ _: 'sm', lg: 'md' }}>{label}</Label>
                    </Box>
                  )}
                  <Box pb={{ _: 'normal1', md: 'normal2' }} px={{ _: 'normal2', md: 'none' }}>
                    <Title size={{ _: 'lg', md: 'xl' }}> {title}</Title>
                  </Box>
                </div>
                {text}
              </StaggerElements>
            </div>
          )}
        </InView>
      </TextContainer>
      <ImageWrapper>
        <Image src={imageSrc} height={420} width={581} layout="responsive" alt={imageAlt ?? ''} />
      </ImageWrapper>
    </InteriorGrid>
  </ColoredCardSection>
);

const InteriorGrid = styled(Container)`
  && {
    align-items: center;
    padding-top: ${getSpace('large1')};
    padding-bottom: ${getSpace('medium2')};
    row-gap: ${pxToRem(40)};
    ${media.md`
      text-align: left;
      row-gap: ${getSpace('medium1')};
      padding-top: ${getSpace('large2')};
      padding-bottom: ${getSpace('large2')};
    `}
    ${media.lg`
      padding-top: ${pxToRem(135)};
      padding-bottom: ${pxToRem(135)};
    `}
  }
`;

const TextContainer = styled.div`
  grid-column: 1 / span 4;
  text-align: center;

  ${media.md`
     grid-column: 2/ span 6;
   `}

  ${media.lg`
     padding: ${pxToRem(108)} 0 ${pxToRem(72)};
     grid-column: 2/ span 5;
     text-align:left
   `};
`;

const ImageWrapper = styled.div`
  mix-blend-mode: darken;
  grid-column: 1/5;
  width: 100%;
  max-height: ${pxToRem(320)};
  max-width: ${pxToRem(320)};
  justify-self: center;
  ${media.md`
    grid-column: 1 / span 8;
    max-height: ${pxToRem(462)};
    max-width: ${pxToRem(581)};
  `}
  ${media.lg`
    max-height: 100%;
    grid-column: 8 / span 5;
  `}
`;
