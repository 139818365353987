import type { RichTextField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const VideoColoredSectionSliceType = 'video_colored_section_slice';

// Required fields in slice
export const requiredPrimaryFields = ['title', 'subtitle', 'video'] as const; // Array of all required primary fields.

type Primary = {
  title: RichTextField;
  subtitle: RichTextField;
  video: string;
};

export type VideoColoredSectionSliceResponse = PrismicSlice<
  typeof VideoColoredSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>
>;
