import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type LargeMediaSectionSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './LargeMediaSectionSliceTypes';
import { LargeMediaSection } from '@yoweb/ui/components/sections/LargeMediaSection';
import { blurParam } from '@yoweb/prismic-slicemachine/slices/slices.utils';

type LargeMediaSectionSliceProps = {
  slice: LargeMediaSectionSliceResponse;
};

const LargeMediaSectionSlice = ({ slice }: LargeMediaSectionSliceProps) => {
  const { title, image, mobileImage, subtitle } = slice.primary;

  const props = {
    title,
    subtitle,
    image: {
      src: image?.url ?? '',
      alt: image?.alt ?? '',
      blurDataURL: `${image?.url}&${blurParam}`,
    },
    mobileImage: {
      src: mobileImage?.url ?? '',
      alt: mobileImage?.alt ?? '',
      blurDataURL: `${mobileImage?.url}&${blurParam}`,
    },
  };

  return <LargeMediaSection {...props} />;
};

export default withPrismicGuardian(LargeMediaSectionSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
