import type { RichTextField, ImageField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const ProjectCardSliceType = 'project_card_slice';

// Required fields in slice
export const requiredPrimaryFieldsWithGrid = ['title'] as const; // Array of all required primary fields.
export const requiredItemsFields = ['title', 'text', 'image', 'author'] as const; // Array of all required items fields.

type PrimaryWithGrid = {
  title: string;
  subtitle?: RichTextField;
};

type Item = {
  title: string;
  text: string;
  author: string;
  image: ImageField;
  authorTitle?: string;
};

type Default = PrismicSlice<
  typeof ProjectCardSliceType,
  null,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>
>;

type WithGrid = PrismicSlice<
  typeof ProjectCardSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFieldsWithGrid>, PrimaryWithGrid>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>,
  'projectCardSliceWithGrid'
>;

export type ProjectCardSliceResponse = Default | WithGrid;
