import * as prismicHelpers from '@prismicio/helpers';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import { HeroWithForm } from '@yoweb/ui/components/sections/HeroSection/HeroWithForm';
import { HeroWithFormInModal } from '@yoweb/ui/components/sections/HeroSection/HeroWithFormInModal';
import { type HeroWithFormSliceResponse, requiredPrimaryFields } from './HeroWithFormSliceTypes';

type HeroWithFormSliceProps = {
  slice: HeroWithFormSliceResponse;
  children?: ReactNode;
};

const HeroWithFormSlice = ({ slice, children, ...rest }: HeroWithFormSliceProps) => {
  const { desktopImage, mobileImage, title, description, buttonLabel } = slice.primary;

  const props = {
    desktopImage: desktopImage.url ?? '',
    mobileImage: mobileImage.url ?? '',
    imageAlt: desktopImage.alt ?? '',
    title: prismicHelpers.asText(title) ?? '',
    description: prismicHelpers.asText(description) ?? '',
    buttonLabel: buttonLabel ? prismicHelpers.asText(buttonLabel) : '',
  };

  if (slice.variation === 'formInModal') {
    return (
      <HeroWithFormInModal {...props} {...rest}>
        {children}
      </HeroWithFormInModal>
    );
  }

  return (
    <HeroWithForm {...props} {...rest}>
      {children}
    </HeroWithForm>
  );
};

export default withPrismicGuardian(HeroWithFormSlice, {
  primaryRequired: requiredPrimaryFields,
});
