import type { RichTextField, ImageField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const HeroWithFormSliceType = 'hero_with_form_slice';

// Required fields in slice
export const requiredPrimaryFields = [
  'title',
  'description',
  'desktopImage',
  'mobileImage',
] as const; // Array of all required primary fields.

export const requiredItemsFields = [] as const; // Array of all required items fields.

type Primary = {
  title: RichTextField;
  description: RichTextField;
  desktopImage: ImageField;
  mobileImage: ImageField;
  buttonLabel?: RichTextField;
};

type Default = PrismicSlice<
  typeof HeroWithFormSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  null,
  'default-slice'
>;

type FormInModalVariation = PrismicSlice<
  typeof HeroWithFormSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  null,
  'formInModal'
>;

export type HeroWithFormSliceResponse = Default | FormInModalVariation;
