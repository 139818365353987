import { useState } from 'react';
import styled, { css } from 'styled-components';
import Image from 'next/legacy/image';
import { AnimatePresence, motion, type Variants } from 'framer-motion';

import { media, pxToRem } from '@yoweb/ui/styles/utils';
import {
  getRadii,
  getSpace,
  getZIndex,
  getColor,
  getFontWeight,
  getFontSize,
} from '@yoweb/ui/styles/utils/theme';
import { Text, Title, Label } from '@yoweb/ui/components/typography';
import { theme } from '@yoweb/ui/styles';
import { DURATIONS, EASINGS } from '@yoweb/ui/components/AnimateIn';

export type ChatRevealCardType = {
  image: string;
  imageAltText: string;
  label?: string;
  title: string;
  description?: string;
  messages: ChatMessage[];
};

type ChatMessage = {
  message: string;
  isLeft: boolean;
  isEmoji?: boolean;
};

const chatVariants = {
  hidden: { opacity: 0 },
  revealed: {
    opacity: 1,
    transition: {
      duration: DURATIONS.d25,
      ease: EASINGS.ease33,
    },
  },
};

const transitions = {
  duration: theme.transitionDuration.shorter / 1000,
  ease: theme.transitionPoints.easeInOut,
};

const avatarVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
    transition: {
      duration: 0,
    },
  },
  revealed: (delay: number) => ({
    opacity: 1,
    scale: 1,
    transition: {
      ease: EASINGS.easeOut,
      delay,
      duration: DURATIONS.d25,
      bounce: 0,
    },
  }),
};

const bubbleVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
    transition: {
      duration: 0,
    },
  },
  revealed: (delay: number) => ({
    opacity: 1,
    scale: 1,
    transition: {
      opacity: {
        ease: 'easeOut',
        delay: DURATIONS.d25 + delay,
        duration: DURATIONS.d25,
      },
      scale: {
        type: 'spring',
        delay: DURATIONS.d25 + delay,
        bounce: 0.4,
      },
    },
  }),
};

export const ChatRevealCard = ({
  image,
  label,
  title,
  description,
  messages,
  imageAltText,
}: ChatRevealCardType) => {
  const [isRevealed, setIsRevealed] = useState(false);

  const withReveal = messages.length > 0;

  return (
    <CardContainer
      role="listbox"
      tabIndex={0}
      onTouchStart={() => setIsRevealed(true)}
      onTouchEnd={() => setIsRevealed(false)}
      onMouseEnter={() => setIsRevealed(true)}
      onMouseLeave={() => setIsRevealed(false)}
      onFocus={() => setIsRevealed(true)}
      onBlur={() => setIsRevealed(false)}
    >
      <BackgroundImage>
        <Image src={image} layout="fill" objectFit="cover" alt={imageAltText} />
      </BackgroundImage>
      <Content>
        <StyledLabel size="md">{label}</StyledLabel>
        <Title size={{ _: 'sm', lg: 'md' }} variant="inverse">
          {title}
        </Title>
        <Text size="md" variant="inverse">
          {description}
        </Text>
      </Content>
      <AnimatePresence>
        {withReveal && isRevealed && (
          <ChatContainer
            variants={chatVariants}
            initial="hidden"
            exit="hidden"
            animate="revealed"
            transition={transitions}
          >
            {messages.map((message, index) => (
              <ChatBubbleContainer key={message.message} isLeft={message.isLeft}>
                {message.isLeft && (
                  <Avatar
                    variants={avatarVariants}
                    initial="hidden"
                    exit="hidden"
                    animate="revealed"
                    custom={(index * theme.transitionDuration.shorter) / 1000}
                  >
                    {/* eslint-disable-next-line react/jsx-no-literals */}
                    {'M'}
                  </Avatar>
                )}
                <ChatBubble
                  isLeft={message.isLeft}
                  variants={bubbleVariants}
                  initial="hidden"
                  exit="hidden"
                  animate="revealed"
                  custom={(index * theme.transitionDuration.shorter) / 1000}
                >
                  {message.isEmoji ? (
                    <Emoji role="img" aria-label="">
                      {message.message}
                    </Emoji>
                  ) : (
                    <Text size={{ _: 'md', lg: 'lg' }}>{message.message}</Text>
                  )}
                </ChatBubble>
              </ChatBubbleContainer>
            ))}
          </ChatContainer>
        )}
      </AnimatePresence>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  border-radius: ${getRadii('large')};
  height: ${pxToRem(480)};
  padding: ${getSpace('normal1')} ${getSpace('normal2')};
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  width: 100%;

  ${media.lg`
    height: ${pxToRem(624)};
  `};

  ${media.xl`
    height: ${pxToRem(624)};
    padding: ${getSpace('normal3')} ${pxToRem(36)};
  `};
`;

const Content = styled.div`
  height: ${pxToRem(168)};
  display: grid;
  row-gap: ${getSpace('small3')};
  position: relative;
  z-index: ${getZIndex('layer')};
  grid-template-rows: auto auto 1fr;

  ${media.md`
    height: ${pxToRem(188)};
  `};

  ${media.xl`
    height: ${pxToRem(158)};
  `};
`;

const BackgroundImage = styled.div`
  height: 100%;
  width: 100%;
  left: 0;
  position: absolute;
  top: 0;

  ::after {
    background: linear-gradient(
      180deg,
      rgba(255 255 255 / 0%) 38.35%,
      rgba(0 0 0 / 0%) 57.92%,
      rgba(0 0 0 / 80%) 85.48%
    );
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const StyledLabel = styled(Label)`
  && {
    color: ${getColor('beach')};
    margin-bottom: ${getSpace('small1')};
  }
`;

const ChatContainer = styled(motion.div)`
  display: grid;
  height: 100%;
  left: 0;
  padding: ${getSpace('normal4')} ${pxToRem(36)} ${getSpace('normal3')} ${getSpace('normal2')};
  position: absolute;
  row-gap: ${getSpace('normal1')};
  top: 0;
  width: 100%;
  z-index: ${getZIndex('chat')};
  grid-auto-rows: min-content;
  background: ${getColor('baseA700')};

  ${media.md`
    row-gap: ${getSpace('normal3')};
    padding: ${getSpace('large2')} ${getSpace('normal4')} ${getSpace('normal2')} ${getSpace(
      'normal3',
    )};
`};
`;

const Avatar = styled(motion.div)`
  align-items: center;
  background: ${getColor('base900')};
  border-radius: 50%;
  bottom: 0;
  color: ${getColor('base000')};
  display: flex;
  font-size: ${getFontSize('labelMd')};
  font-weight: ${getFontWeight('bold')};
  height: ${getSpace('normal3')};
  justify-content: center;
  left: ${pxToRem(0)};
  position: absolute;
  width: ${getSpace('normal3')};

  ${media.md`
    height: ${getSpace('normal4')};
    width: ${getSpace('normal4')};
  `};
`;

const ChatBubbleContainer = styled.div<{ isLeft: boolean }>`
  justify-self: ${({ isLeft }) => (!isLeft ? 'end' : 'start')};
  position: relative;
`;

const ChatBubble = styled(motion.div)<{ isLeft: boolean }>`
  background: ${getColor('base000')};
  padding: ${pxToRem(12)} ${getSpace('normal1')};
  border-radius: ${getRadii('medium')} ${getRadii('medium')}
    ${({ isLeft }) => !isLeft && getRadii('medium')}
    ${({ isLeft }) => !isLeft && getRadii('medium')};
  margin-left: ${getSpace('normal4')};
  transform-origin: 100% 100%;

  ${media.lg`
    margin-left: ${getSpace('medium1')};
  `};
  ${({ isLeft }) =>
    isLeft &&
    css`
      transform-origin: 0% 100%;
    `}
`;

const Emoji = styled.span`
  height: ${getSpace('normal3')};
  width: ${getSpace('normal3')};
`;
