import styled from 'styled-components';
import Image from 'next/legacy/image';

import { getRadii, getSpace } from '@yoweb/ui/styles/utils/theme';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { Title, Text } from '@yoweb/ui/components/typography';
import { QuoteIcon } from '@yoweb/ui/components/Icon/QuoteIcon';
import { AnimateWhenInView, InView, StaggerElements } from '@yoweb/ui/components/AnimateIn';

type TranslatedProjectCardQuote = {
  text: string;
  author: string;
  authorTitle?: string;
};

type ProjectCardProps = {
  image: string;
  title: string;
  quote: TranslatedProjectCardQuote;
  imageAltText: string;
};

export const ProjectCard = ({ image, title, quote, imageAltText }: ProjectCardProps) => (
  <Card>
    <AnimateWhenInView>
      <ImageWrap>
        <Image src={image} layout="fill" objectFit="cover" alt={imageAltText} />
      </ImageWrap>
    </AnimateWhenInView>
    <InView options={{ rootMargin: '-100px 0px' }}>
      {(isInView) => (
        <Content>
          <StaggerElements isInView={isInView}>
            <Title size={{ _: 'md', md: 'sm', lg: 'md' }}>{title}</Title>
            {quote && (
              <Quote>
                <QuoteIcon variant="attention" style={{ alignSelf: 'start', marginTop: '5px' }} />
                <QuoteContent>
                  <Text size={{ _: 'xl', md: 'lg', lg: 'xl' }}>{quote.text}</Text>
                  <Text size={{ _: 'xl', md: 'lg', lg: 'xl' }} variant="muted">
                    <Text as="span" weight="bold" size="md">
                      {quote.author}
                    </Text>
                    {quote.authorTitle && (
                      <Text as="span" size="md">
                        {/* eslint-disable-next-line react/jsx-no-literals */}
                        {`, ${quote.authorTitle}`}
                      </Text>
                    )}
                  </Text>
                </QuoteContent>
              </Quote>
            )}
          </StaggerElements>
        </Content>
      )}
    </InView>
  </Card>
);

const Card = styled.article`
  display: grid;
  row-gap: ${getSpace('normal4')};

  ${media.md`
    row-gap: ${getSpace('normal2')};
  `}

  ${media.lg`
    row-gap: ${getSpace('normal4')};
  `}
`;

const ImageWrap = styled.div`
  height: 0;
  overflow: hidden;
  padding-top: 120%;
  position: relative;
  border-radius: ${getRadii('medium')};
`;

const Content = styled.div`
  display: grid;
  row-gap: ${getSpace('normal2')};
`;

const Quote = styled.div`
  align-items: start;
  display: grid;
  grid-template-columns: ${getSpace('normal4')} 1fr;

  ${media.md`
    grid-template-columns: ${pxToRem(28)} 1fr;
  `}

  ${media.lg`
    grid-template-columns: ${getSpace('normal4')} 1fr;
  `}
`;

const QuoteContent = styled.div`
  display: grid;
  row-gap: ${pxToRem(28)};
`;
