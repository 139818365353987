import type { FilledLinkToWebField, ImageField, RichTextField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const ImagesWithQuoteSliceType = 'images_with_quote_slice';
export const ImagesWithQuoteType = 'images_with_quote';

// Required fields in slice
const commonRequiredFields = ['image1', 'image2'] as const;
export const requiredPrimaryFields = [
  ...commonRequiredFields,
  'quote',
  'source',
  'sourceHref',
] as const; // Array of all required primary fields.
export const requiredPrimaryFieldsWithCaption = [
  ...commonRequiredFields,
  'captionTitle',
  'captionDescription',
] as const;

type CommonPrimary = {
  label?: string;
  title?: RichTextField;
  image1: ImageField;
  image2: ImageField;
};

type Primary = {
  quote: RichTextField;
  source: RichTextField;
  sourceHref: FilledLinkToWebField;
} & CommonPrimary;

type PrimaryWithCaption = {
  captionTitle: string;
  captionDescription: string;
} & CommonPrimary;

export type DefaultSliceResponse = PrismicSlice<
  typeof ImagesWithQuoteSliceType | typeof ImagesWithQuoteType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  null,
  'default'
>;

export type WithCaptionResponse = PrismicSlice<
  typeof ImagesWithQuoteSliceType | typeof ImagesWithQuoteType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFieldsWithCaption>, PrimaryWithCaption>,
  null,
  'withCaption'
>;

export type ImagesWithQuoteSliceResponse = DefaultSliceResponse | WithCaptionResponse;
