import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const ChatMessageSliceType = 'chat_message_slice';

// Required fields in slice
export const requiredPrimaryFields = [] as const; // Array of all required primary fields.
export const requiredItemsFields = ['cardId', 'message', 'emoji', 'left'] as const; // Array of all required items fields.

type Item = {
  cardId: number;
  message: string;
  emoji: boolean;
  left: boolean;
};

export type ChatMessageSliceResponse = PrismicSlice<
  typeof ChatMessageSliceType,
  null,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>
>;
