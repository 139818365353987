import {
  type ChatMessageSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './ChatMessageSliceTypes';
import { ChatRevealCard } from '@yoweb/ui/components/marketing/ChatRevealCard';
import { replaceNullWithUndefined } from '@yoweb/utils/replaceNullWithUndefined';
import type { CardCarouselSectionSliceResponse } from '@yoweb/prismic-slicemachine/slices/CardCarouselSectionSlice/CardCarouselSectionSliceTypes';
import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';

type ChatMessageSliceProps = {
  cardCarouselItem: CardCarouselSectionSliceResponse['items'][0];
  slice: ChatMessageSliceResponse;
};

const ChatMessageSlice = ({ cardCarouselItem, slice }: ChatMessageSliceProps) => {
  // This check is needed since the slice is used with CardCarouselSectionSlice
  // and cardCarouselItem prop is not present when opening Slicemachine simulator.
  if (!cardCarouselItem) {
    // eslint-disable-next-line no-console
    console.error('ChatMessageSlice requires a cardCarouselItem');

    return null;
  }

  const {
    title,
    description,
    label,
    image,
    cardId: cardId,
  } = replaceNullWithUndefined(cardCarouselItem);

  const props = {
    title,
    description,
    label,
    image: image?.url ?? '',
    imageAltText: image?.alt ?? '',
    messages:
      slice?.items
        .filter((item) => item.cardId === cardId)
        .map(({ message, emoji: isEmoji, left: isLeft }) => ({
          message,
          isEmoji,
          isLeft,
        })) ?? [],
  };

  return <ChatRevealCard {...props} />;
};

export default withPrismicGuardian(ChatMessageSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
