import styled, { css } from 'styled-components';
import React from 'react';

import { Container } from '@yoweb/ui/components/containers';
import { Box, type BoxProps } from '@yoweb/ui/components/Box';
import { Title } from '@yoweb/ui/components/typography';
import { media } from '@yoweb/ui/styles/utils';
import { getSpace, getColor, getRadii } from '@yoweb/ui/styles/utils/theme';

export type CtaWithFormSectionProps = {
  ctaTitle: string;
  ctaDescription?: string;
  formTitle: string;
  formDescription?: string;
  backgroundColor?: BoxProps['backgroundColor'];
  buttonLabel?: string;
  children?: ReactNode;
  atBottom?: boolean;
} & DataTestId;

const IDS = {
  container: 'form-cta-container',
  form: 'form-cta-form-wrapper',
  title: 'form-cta-title',
  description: 'form-cta-description',
};

type CtaWithFormSectionChildProps = {
  title: string;
  description: string;
  buttonLabel: string;
};

export const CtaWithFormSection = ({
  ctaTitle,
  ctaDescription,
  formTitle,
  formDescription,
  backgroundColor,
  buttonLabel,
  children,
  atBottom,
}: CtaWithFormSectionProps) => {
  /** Inject the child component sent from the parent with all the necessary props that the parent did not have access to at the time of sending */
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<CtaWithFormSectionChildProps>, {
        title: formTitle,
        description: formDescription,
        buttonLabel,
      });
    }
    return child;
  });

  return (
    <StyledWrapper atBottom={atBottom}>
      <CtaContainer
        withGrid
        backgroundColor={backgroundColor}
        atBottom={atBottom}
        data-testid={IDS.container}
      >
        <TextWrapper>
          <CtaTitle size={{ _: 'md', lg: 'xl' }} data-testid={IDS.title}>
            {ctaTitle}
          </CtaTitle>
          <Title size={{ _: 'xs', lg: 'sm' }} weight="regular" data-testid={IDS.description}>
            {ctaDescription}
          </Title>
        </TextWrapper>
        <FormWrapper data-testid={IDS.form}>
          <Title size="xs">{formTitle}</Title>
          {formDescription && (
            <FormDescription as="p" size="xs" variant="muted" weight="regular">
              {formDescription}
            </FormDescription>
          )}
          {childrenWithProps}
        </FormWrapper>
      </CtaContainer>
    </StyledWrapper>
  );
};

const FormDescription = styled(Title)`
  && {
    grid-column: 1/5;
    text-align: center;
    padding-top: ${getSpace('small4')};

    ${media.md`
      text-align: left;
      padding-top: ${getSpace('normal2')};
    `}
  }
`;

const StyledWrapper = styled(Box)<{ atBottom?: boolean }>`
  && {
    ${({ atBottom }) =>
      atBottom &&
      css`
        margin-bottom: -${getSpace('normal1')};

        ${media.md`
          margin-bottom: -${getSpace('normal3')};
        `}
      `}
  }
`;

const CtaContainer = styled(Container)<{ atBottom?: boolean }>`
  && {
    align-items: center;
    margin-top: ${getSpace('large1')};
    margin-bottom: ${getSpace('large2')};

    ${media.lg`
      margin-bottom: ${getSpace('xl2')};
    `}

    ${({ atBottom }) =>
      atBottom &&
      css`
        margin-bottom: ${getSpace('large3')};

        ${media.lg`
          margin-bottom: ${getSpace('xl3')};
        `}
      `}
  }
`;

const TextWrapper = styled.div`
  && {
    grid-column: 1/5;
    padding-bottom: ${getSpace('normal3')};

    ${media.md`
      grid-column: 1/9;
    `}

    ${media.lg`
      grid-column: 1/6;
      padding-bottom: 0;
    `}
  }
`;

const CtaTitle = styled(Title)`
  && {
    padding-bottom: ${getSpace('normal2')};
  }
`;

const FormWrapper = styled.div`
  grid-column: 1/5;
  padding: ${getSpace('normal1')};
  background: ${getColor('base000')};
  border-radius: ${getRadii('large')};
  ${media.md`
    grid-column: 1/9;
  `}
  ${media.lg`
    padding: ${getSpace('normal4')};
    grid-column: 7/12;
  `};
`;
